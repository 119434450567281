<template>
  <div class="demand-hall">
    <img class="dh-title" :src="require('../../../assets/image/index/index_a.png')" />
    <div class="dh-main">

      <div class="dhm-left">
        <div :class="{'select_span_index':nowIndex===index}" class="dhml-button" @click="changeNowItem(index)"
             v-for="(item,value,index) in xuqiu_list" :key="index">
          <span>{{ value }}</span>
          <Icon type="ios-arrow-forward" />
        </div>
      </div>

      <div class="dhm-right">
        <div class="dhmr-title">
          <img width="24" height="24" :src="require('../../../assets/image/index/fire_icon.png')" />
          <span class="dhmr-title-text">热门推荐</span>
        </div>

        <div class="dhmr-main" v-for="(item,index) in nowShowItem" :key="item.id">
          <div class="dhmrm-left">
            <div class="dhmrm-left-fontA ellipse-1">
              <router-link style="color: #333333;" :to="{ path: '/home/demandDetail', query: { id: item.id } }" target="_blank">
                {{ item.title }}
              </router-link>
            </div>
            <div class="dhmrm-left-fontB ellipse-1">
              {{ item.content }}
            </div>
          </div>
          <div class="dhmrm-right">
            <div class="dhmrm-right-fontA">预算金额：<span style="color: rgba(255, 82, 0, 1)">协商确定</span></div>
            <router-link
              :to="{ path: '/home/demandDetail', query: { id: item.id } }"
              target="_blank"
              class="dhmrm-right-fontB">查看详情 >
            </router-link>
          </div>
        </div>

      </div>


    </div>


    <div @click="goDemandHall" style="margin-top:45px;margin-bottom: 45px " class="dhmrm-right-fontB">查看更多 ></div>
  </div>
</template>

<script>
import {
  getIndexInformationList,
  getIndexRequirementData
} from "../../../plugins/api/indexApi";
import { getKnowledgeListById } from "../../../plugins/api/encyclopediaKnowledgeApi";

export default {
  name: "demandAndInformation",
  data() {
    return {
      nowIndex: 0,
      nowShowItem: [],
      xuqiu_list: []
    };
  },
  mounted() {
    this.getRequirementList();
  },
  methods: {
    changeNowItem(index) {
      this.nowIndex = index;
      this.nowShowItem = this.xuqiu_list[Object.keys(this.xuqiu_list)[index]].length > 5 ? this.xuqiu_list[Object.keys(this.xuqiu_list)[index]].slice(0, 5) : this.xuqiu_list[Object.keys(this.xuqiu_list)[index]];

    },
    goDemandHall() {
      this.$router.push({
        path: "/home/DemandHall"
      });
    },
    async getRequirementList() {
      const json = await getIndexRequirementData();
      if (json && json.code === 0) {
        let data = json.result;
        let index = Object.keys(data).indexOf("技术需求");
        if (index !== -1) {
          const obj = {};
          obj["技术需求"] = data["技术需求"];
          delete data["技术需求"];
          Object.keys(data).forEach(item => {
            obj[item] = data[item];
          });
          data = obj;
        }
        this.xuqiu_list = data;
      }
      this.nowShowItem = this.xuqiu_list[Object.keys(this.xuqiu_list)[0]].length > 5 ? this.xuqiu_list[Object.keys(this.xuqiu_list)[0]].slice(0, 5) : this.xuqiu_list[Object.keys(this.xuqiu_list)[0]];


    },

    goBaike() {
      this.$router.push({
        path: "/home/baike",
        query: {
          tab: 1
        }
      });
    }


  }
};
</script>

<style scoped lang="scss">
.demand-hall {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(247, 248, 250, 1);
}

.dh-title {
  width: 444px;
  height: 44px;
  margin-top: 50px;
}

.dh-main {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.dhm-left {
  width: 200px;
  display: flex;
  flex-direction: column;
}

.dhml-button {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding-left: 36px;
  padding-right: 25px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #2f406d;
  cursor: pointer;
}

.select_span_index {
  background-color: #FFFFFF;
  font-weight: 500;
  color: #333333;
  border-left: 4px solid rgba(255, 82, 0, 1);
}

.dhm-right {
  background-color: #FFFFFF;
  width: 1000px;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.dhmr-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.dhmr-title-text {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
}

.dhmr-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E5E8ED;;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dhmrm-left {
  width: 730px;
  display: flex;
  flex-direction: column;

}

.dhmrm-left-fontA {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.dhmrm-left-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 8px;
}

.dhmrm-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dhmrm-right-fontA {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.dhmrm-right-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  line-height: 14px;
  margin-top: 8px;
  cursor: pointer;
}
</style>
